<template>
  <div>
    <heade></heade>
    <div class="com">
      <div class="com_box">
        <div class="video_box">
          <P>
            全国教科研协作专区，由全国各地区/实验校等协作完成，届时会同步更新资源。
          </P>
        </div>
        <div class="item_1">
          <ul>
            <li>
              <a href="javascript:;">弟子规教研专区</a>
            </li>
            <li>
              <a href="javascript:;">三字经教研专区</a>
            </li>
            <li>
              <a href="javascript:;">千字文教研专区</a>
            </li>
            <li>
              <a href="javascript:;">古典诗词欣赏教研专区</a>
            </li>
          </ul>
          <ul>
            <li>
              <a href="javascript:;">孟子（上）教研专区</a>
            </li>
            <li>
              <a href="javascript:;">孟子（下）教研专区</a>
            </li>
            <li>
              <a href="javascript:;">声律启蒙（上）教研专区</a>
            </li>
            <li>
              <a href="javascript:;">声律启蒙（下）教研专区</a>
            </li>
          </ul>
          <ul>
            <li>
              <a href="javascript:;">大学教研专区</a>
            </li>
            <li>
              <a href="javascript:;">中庸教研专区</a>
            </li>
            <li>
              <a href="javascript:;">论语（上）教研专区</a>
            </li>
            <li>
              <a href="javascript:;">论语（下）教研专区</a>
            </li>
          </ul>
        </div>
        <div class="item_2">
          <ul>
            <li>
              <a href="javascript:;">首孝悌教研专区</a>
            </li>
            <li>
              <a href="javascript:;">做君子教研专区</a>
            </li>
            <li>
              <a href="javascript:;">讲仁爱教研专区</a>
            </li>
            <li>
              <a href="javascript:;">重民本教研专区</a>
            </li>
          </ul>
          <ul>
            <li>
              <a href="javascript:;">守诚信教研专区</a>
            </li>
            <li>
              <a href="javascript:;">崇正义教研专区</a>
            </li>
            <li>
              <a href="javascript:;">尚和合教研专区</a>
            </li>
            <li>
              <a href="javascript:;">求大同教研专区</a>
            </li>
          </ul>
          <ul>
            <li>
              <a href="javascript:;">养廉洁教研专区</a>
            </li>
            <li>
              <a href="javascript:;">亲仁德教研专区</a>
            </li>
            <li>
              <a href="javascript:;">道中庸教研专区</a>
            </li>
            <li>
              <a href="javascript:;">合自然教研专区</a>
            </li>
          </ul>
        </div>
        <div class="item_2">
          <ul>
            <li>
              <a href="javascript:;">中国传统节日教研专区</a>
            </li>
            <li>
              <a href="javascript:;">中国传统节气教研专区</a>
            </li>
            <li>
              <a href="javascript:;">茶文化教研专区</a>
            </li>
            <li>
              <a href="javascript:;">中国传统文化相声欣赏教研专区</a>
            </li>
          </ul>
          <ul>
            <li>
              <a href="javascript:;">传统吹管类乐曲欣赏教研专区</a>
            </li>
            <li>
              <a href="javascript:;">传统弹拨类乐曲欣赏教研专区</a>
            </li>
            <li>
              <a href="javascript:;">传统曲艺艺术欣赏（上）教研专区</a>
            </li>
            <li>
              <a href="javascript:;">传统曲艺艺术欣赏（下）教研专区</a>
            </li>
          </ul>
          <ul>
            <li>
              <a href="javascript:;">中国传统戏曲艺术（上）教研专区</a>
            </li>
            <li>
              <a href="javascript:;">中国传统戏曲艺术（下）教研专区</a>
            </li>
            <li>
              <a href="javascript:;">中国传统建筑艺术教研专区</a>
            </li>
            <li>
              <a href="javascript:;">传统家具艺术教研专区</a>
            </li>
          </ul>
        </div>
        <div class="item_2">
          <ul>
            <li>
              <a href="javascript:;">上古神话教研专区</a>
            </li>
            <li>
              <a href="javascript:;">周朝教研专区</a>
            </li>
            <li>
              <a href="javascript:;">先秦文学和思想教研专区</a>
            </li>
            <li>
              <a href="javascript:;">大秦帝国教研专区</a>
            </li>
          </ul>
          <ul>
            <li>
              <a href="javascript:;">汉朝教研专区</a>
            </li>
            <li>
              <a href="javascript:;">三国两晋南北朝教研专区</a>
            </li>
            <li>
              <a href="javascript:;">唐朝教研专区</a>
            </li>
            <li>
              <a href="javascript:;">盛唐文学教研专区</a>
            </li>
          </ul>
          <ul>
            <li>
              <a href="javascript:;">宋朝教研专区</a>
            </li>
            <li>
              <a href="javascript:;">元朝教研专区</a>
            </li>
            <li>
              <a href="javascript:;">明朝教研专区</a>
            </li>
            <li>
              <a href="javascript:;">清朝教研专区</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import "@css/ico.css";
import "@css/sanji.css";
import "@css/foot.css";
import heade from "@components/heade.vue";
import foot from "@components/foot.vue";
export default {
  components: {
    heade,
    foot,
  },
  created: function () {
    document.title = this.$route.meta.title;
  },
};
</script>

<style scoped>
.com {
  width: 100%;
  min-width: 1484px;
  height: 3577px;
  background: url(../../assets/images/keyanxiezuo.jpg);
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}
.com_box {
  width: 1484px;
  height: 3577px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.video_box {
  width: 970px;
  height: 630px;
  padding-top: 454px;
  margin: 0 270px;
}

.video_box P {
  position: relative;
  text-indent: 2em;
  font-size: 40px;
  font-family: kaiti;
  font-weight: bold;
  color: #000;
  top: 50%;
  transform: translateY(-50%);
}
.item_1 {
  width: 1184px;
  height: 376px;
  margin: 0 auto;
  padding-top: 123px;
  display: flex;
  justify-content: center;
}
.item_2 {
  width: 1184px;
  height: 376px;
  margin: 0 auto;
  padding-top: 100px;
  display: flex;
  justify-content: center;
}
ul {
  width: 360px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
li {
  line-height: 50px;
  font-size: 22px;
  font-family: "kaiti";
  font-weight: bold;
}
</style>